import clsx from 'clsx'
import type { FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { filterNonEmpty } from '../utilities/filterNonEmpty'
import { Container } from './Container'
import { FrontArticles } from './FrontArticles'
import { FrontButton } from './FrontButton'
import { FrontEvents } from './FrontEvents'
import { FrontFiles } from './FrontFiles'
import { FrontMagazineArticles } from './FrontMagazineArticles'
import styles from './FrontPage.module.sass'
import { FrontTvArticle } from './FrontTvArticle'
import { MailingListBanner } from './MailingListBanner'
import { MailingListBannerSecondary } from './MailingListBannerSecondary'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type FrontPageProps = NonNullable<PageProps['page']['frontPage']>

export const FrontPage: FunctionComponent<FrontPageProps> = ({
	events,
	recommendedArticleList,
	files,
	magazineArticles,
	articlesPageUrl,
	mailingList,
	mailingListSecondary,
	eventsPageUrl,
	filesPageUrl,
	podcastPageUrl,
	productNewsPageUrl,
	tvPageUrl,
	wikiPageUrl,
	podcastArticles,
	productNewsArticles,
	tvArticles,
	wikiArticles,
}) => {
	const translate = useTranslate()

	const isItemShort = tvArticles.length + productNewsArticles.length + podcastArticles.length === 1

	return (
		<div className={styles.wrapper}>
			<Container size="normal">
				<div className={clsx(styles.content, styles.topContent)}>
					<div className={styles.grid}>
						{magazineArticles && magazineArticles.length > 0 && (
							<FrontMagazineArticles
								articles={magazineArticles}
								iconName="paperPlane"
								title={translate('frontPage.title.magazineArticles')}
							/>
						)}
						<div className={styles.rightContent}>
							{recommendedArticleList?.items && recommendedArticleList.items.length > 0 && (
								<FrontArticles
									objectFit="cover"
									articles={recommendedArticleList.items.map((item) => item.article).filter(filterNonEmpty)}
									title={translate('frontPage.title.recommendedArticles')}
									iconName="guide"
									mobileLayout="horizontal"
								/>
							)}
						</div>
					</div>
					{articlesPageUrl && (
						<FrontButton
							buttonLink={articlesPageUrl}
							buttonLabel={translate('frontPage.button.label.magazineArticles')}
						/>
					)}
				</div>
				<div className={clsx(styles.content, isItemShort ? styles.frontPageShortVideo : undefined)}>
					<div className={styles.middleContent}>
						<div className={styles.column}>
							{tvArticles && tvArticles.length > 0 && (
								<FrontTvArticle
									article={tvArticles[0]}
									iconName="videos"
									buttonLink={tvPageUrl}
									buttonLabel={translate('frontPage.button.label.tvArticles')}
									title={translate('frontPage.title.tvArticles')}
								/>
							)}
						</div>
						{productNewsArticles && productNewsArticles.length > 0 && (
							<div className={styles.column}>
								<FrontArticles
									title={translate('frontPage.title.productNewsArticles')}
									iconName="hot"
									articles={productNewsArticles}
									buttonLink={productNewsPageUrl}
									buttonLabel={translate('frontPage.button.label.productNewsArticles')}
									mobileLayout="horizontal"
								/>
							</div>
						)}
						{podcastArticles && podcastArticles.length > 0 && (
							<div className={styles.column}>
								<FrontArticles
									title={translate('frontPage.title.podcastArticles')}
									iconName="headset"
									articles={podcastArticles}
									buttonLink={podcastPageUrl}
									buttonLabel={translate('frontPage.button.label.podcastArticles')}
									mobileLayout="horizontal"
								/>
							</div>
						)}
					</div>
					{mailingList && (
						<div className={clsx(isItemShort && styles.mailingListWrapperNoMarginTop, styles.mailingListWrapper)}>
							<MailingListBanner list={mailingList} />
						</div>
					)}
				</div>
				<div className={clsx(styles.content, styles.wikiContent)}>
					{wikiArticles && wikiArticles.length > 0 && (
						<FrontArticles
							articles={wikiArticles}
							title={translate('frontPage.title.wikiArticles')}
							isHorizontal
							objectFit="cover"
							iconName="hat"
							buttonLink={wikiPageUrl}
							buttonLabel={translate('frontPage.button.label.wikiArticles')}
							mobileLayout="horizontal"
						/>
					)}
				</div>
				{mailingListSecondary && (
					<div className={styles.mailingListWrapper}>
						<MailingListBannerSecondary list={mailingListSecondary} />
					</div>
				)}
				<div
					className={clsx(
						styles.content,
						styles.bottomContent,
						events && events.length === 0 && styles.bottomContentFilesOnly,
					)}
				>
					{events && events.length > 0 && (
						<FrontEvents
							events={events}
							title={translate('frontPage.title.events')}
							iconName="calendar"
							buttonLink={eventsPageUrl}
							buttonLabel={translate('frontPage.button.label.events')}
						/>
					)}
					{files && files.length > 0 && (
						<FrontFiles
							noEvents={events && events.length === 0}
							files={files.filter(filterNonEmpty)}
							iconName="downloads"
							title={translate('frontPage.title.files')}
							buttonLabel={translate('frontPage.button.label.files')}
							buttonLink={filesPageUrl}
						/>
					)}
				</div>
			</Container>
		</div>
	)
}
