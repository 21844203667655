import { useState, type FunctionComponent } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { AsideMenu } from './AsideMenu'
import { Container } from './Container'
import styles from './EventsPage.module.sass'
import { ItemListEvents } from './ItemListEvents'

export type EventsPageProps = NonNullable<PageProps['page']['eventsPage']> & {
	isArchive?: boolean
}

export const EventsPage: FunctionComponent<EventsPageProps> = ({
	heading,
	listEvent,
	listMonths,
	buttonUrl,
	isArchive = false,
}) => {
	const [activeMonth, setActiveMonth] = useState('')

	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				<AsideMenu
					heading={heading}
					months={listMonths}
					activeCategoryId={activeMonth}
					setActiveMonth={(e) => setActiveMonth(e)}
					activeMonth={activeMonth}
					buttonUrl={buttonUrl ?? undefined}
					isArchive={isArchive}
				/>
				<Container size="small" disableGutters>
					<ItemListEvents activeMonth={activeMonth} listMonths={listMonths} listEvent={listEvent} />
				</Container>
			</div>
		</Container>
	)
}
