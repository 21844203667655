import clsx from 'clsx'
import { HidingHeader } from 'hiding-header-react'
import 'hiding-header/dist/style.css'
import Image from 'next/image'
import Link from 'next/link'
import { type FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import type { PageProps } from '../pages/[[...path]]'
import { contemberLinkToHref, contemberLinkToHrefTargetRel } from '../utilities/contemberLinkToHref'
import { Button } from './Button'
import styles from './Header.module.sass'
import { HeaderMobileMenu } from './HeaderMobileMenu'
import { HeaderNestedLinks, type HeaderNestedLinksProps } from './HeaderNestedLinks'
import { Icon } from './Icon'
import { SitePicker } from './SitePicker'
import { useSite } from './contexts/SiteContextProvider'
import { useTranslate } from './contexts/TranslationsContextProvider'

export type HeaderProps = NonNullable<PageProps['header']>

export const Header: FunctionComponent<HeaderProps> = ({ logo, links, contactPageLink, siteTitle, listSite }) => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	const [activeLinkPosition, setActiveLinkPosition] = useState<{ offsetLeft: number | null; width: number } | null>(
		null,
	)
	const headerRef = useRef<HTMLHeadElement | null>(null)
	const [headerCount, setHeaderCount] = useState(0)
	const [hoveredLinkPosition, setHoveredLinkPosition] = useState<{
		offsetLeft: number | null
		width: number | null
	} | null>(null)

	const onLinkClick = useCallback(() => {
		setHeaderCount((count) => count + 1)
	}, [])

	useEffect(() => {
		if (links?.every((link) => !link?.isActive)) {
			setActiveLinkPosition(null)
		}
	}, [links])

	const translation = useTranslate()
	const { baseUrl } = useSite()

	return (
		<div className={styles.headWrapper}>
			<div className={styles.head}>
				<HidingHeader>
					<header
						key={headerCount}
						className={clsx(styles.wrapper, (hoveredLinkPosition || activeLinkPosition) && styles.is_lineVisible)}
						style={{
							'--Header-activeLink-offsetLeft':
								hoveredLinkPosition?.offsetLeft || activeLinkPosition?.offsetLeft
									? `${(hoveredLinkPosition?.offsetLeft || activeLinkPosition?.offsetLeft) ?? 0}px`
									: '50%',
							'--Header-activeLink-width': `${(hoveredLinkPosition?.width || activeLinkPosition?.width) ?? 0}px`,
						}}
						ref={headerRef}
					>
						<div className={styles.in}>
							{logo && (
								<Link className={styles.logoLink} href={baseUrl}>
									<Image
										src={logo?.url}
										alt="Logo"
										width={logo.width}
										height={logo.height}
										className={styles.logo}
										priority
										quality={75}
									/>
								</Link>
							)}
							<ul
								className={styles.links}
								onMouseLeave={() => {
									if (!activeLinkPosition) {
										setHoveredLinkPosition({ offsetLeft: hoveredLinkPosition?.offsetLeft ?? null, width: null })
									} else {
										setHoveredLinkPosition(null)
									}
								}}
							>
								{links?.map(
									(link) =>
										link && (
											<HeaderLink
												key={link.id}
												link={link}
												setHoveredLinkPosition={setHoveredLinkPosition}
												setActiveLinkPosition={setActiveLinkPosition}
												onLinkClick={onLinkClick}
											/>
										),
								)}
							</ul>
							{contactPageLink && (
								<div className={styles.button}>
									<Button
										type="link"
										size="small"
										variant="primary"
										href={contactPageLink.externalLink ?? contactPageLink.internalLink?.url ?? ''}
									>
										{contactPageLink.title ?? translation('header.contactLabel')}
									</Button>
								</div>
							)}
							<SitePicker site={siteTitle} otherSites={listSite} />

							<button type="button" className={styles.sandwich} onClick={() => setIsMobileMenuOpen(true)}>
								<Icon name="sandwich" />
							</button>
						</div>
					</header>
					<div className={styles.bottomBorder} />
				</HidingHeader>

				<HeaderMobileMenu
					isOpen={isMobileMenuOpen}
					setIsOpen={setIsMobileMenuOpen}
					links={links}
					contactPageLink={contactPageLink?.externalLink}
				/>
			</div>
		</div>
	)
}

const HeaderLink: FunctionComponent<
	{
		link: NonNullable<HeaderProps['links']>[number]
		setHoveredLinkPosition: (position: { offsetLeft: number; width: number } | null) => void
		setActiveLinkPosition: (position: { offsetLeft: number; width: number } | null) => void
	} & Pick<HeaderNestedLinksProps, 'onLinkClick'>
> = ({ link, setHoveredLinkPosition, setActiveLinkPosition, onLinkClick }) => {
	const linkRef = useRef<HTMLDivElement | null>(null)

	useEffect(() => {
		if (!link?.isActive) {
			return
		}
		if (!linkRef.current) {
			return
		}
		setActiveLinkPosition({
			offsetLeft: linkRef.current.offsetLeft + linkRef.current.offsetWidth / 2,
			width: linkRef.current.offsetWidth,
		})
		window.addEventListener('resize', () => {
			if (!linkRef.current) {
				return
			}
			setActiveLinkPosition({
				offsetLeft: linkRef.current.offsetLeft + linkRef.current.offsetWidth / 2,
				width: linkRef.current.offsetWidth,
			})
		})
	}, [link?.isActive, setActiveLinkPosition])

	if (!link) {
		return null
	}

	return (
		<li className={styles.item}>
			<Link
				className={styles.link}
				{...contemberLinkToHrefTargetRel(link)}
				onMouseEnter={() => {
					if (!linkRef.current) {
						return
					}
					setHoveredLinkPosition({
						offsetLeft: linkRef.current.offsetLeft + linkRef.current.offsetWidth / 2,
						width: linkRef.current.offsetWidth,
					})
				}}
			>
				<div ref={linkRef} className={styles.linkIn}>
					{link.title ?? contemberLinkToHref(link)}
					{link.nestedLinks && (
						<div className={styles.chevron}>
							<Icon name="chevron" />
						</div>
					)}
				</div>
			</Link>

			{link.nestedLinks && <HeaderNestedLinks items={link.nestedLinks.items} onLinkClick={onLinkClick} />}
		</li>
	)
}
